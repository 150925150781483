<template>
    <div v-if="$can('correccion.menu.consultar.ajuste')">
        <ConsultaAjusteEntrada />
    </div>
</template>
    
<script>
    import ConsultaAjusteEntrada from './Modulos/ConsultaAjusteEntrada.vue'

    export default {
    
        components: {
            ConsultaAjusteEntrada
        },
    
        data () {
            return {
                spinnerloading: false
            }
        }
    }
</script>